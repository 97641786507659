<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <p>
      This is my validation environment for AWS and Website development.
      <br>
      <a href="https://aws.amazon.com/jp/" target="_blank" rel="noopener" style="color: orange;">Amazon Web Service</a>
    </p>
    <h3>Essential Links</h3>
    <ul>
      <li><a href="https://www.jma.go.jp/bosai/en_nowc/#zoom:5/lat:37.370157/lon:140.712891/colordepth:normal/elements:hrpns&slmcs&slmcs_fcst&liden" target="_blank" rel="noopener" style="color: orange;">JMA nowcast</a></li>
      <li><a href="https://jp.ufc.com/" target="_blank" rel="noopener" style="color: orange;">UFC</a></li>
    </ul>
    <!---
    <h3>Ecosystem</h3>
    <ul>
      <li><a href="https://router.vuejs.org" target="_blank" rel="noopener">vue-router</a></li>
      <li><a href="https://vuex.vuejs.org" target="_blank" rel="noopener">vuex</a></li>
      <li><a href="https://github.com/vuejs/vue-devtools#vue-devtools" target="_blank" rel="noopener">vue-devtools</a></li>
      <li><a href="https://vue-loader.vuejs.org" target="_blank" rel="noopener">vue-loader</a></li>
      <li><a href="https://github.com/vuejs/awesome-vue" target="_blank" rel="noopener">awesome-vue</a></li>
    </ul>
  -->
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.min.css' // BootstrapのCSSファイルをインポート
import 'bootstrap' // BootstrapのJavaScriptファイルをインポート

const app = createApp(App)
app.config.productionTip = false
app.mount('#app')



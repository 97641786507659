<template>
  <img alt="Cloud logo" src="./assets/kkrn_icon_kumo_6.png" width="200" height="200">
  <HelloWorld msg="Welcome to my homepage."/>
  <div class="about">
    <!--<button @click="clicked" >API呼び出しボタン</button>-->
    <b-button
      size="lg"
      variant="outline-primary">
      API Calling Button
    </b-button>
  </div>
  <div class="about">
{{ getRes() }}</div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import axios from 'axios'

export default {
  data(){
    return {
    response: 'Fine'
    }
  },
  name: 'App',
  components: {
    HelloWorld
  },  
  methods:{
    clicked() {
      const vm = this
    console.log('start request!!')
      axios.get('http://13.211.234.12:3000/') //EC2のURLを記載して通信
        .then((res) => {
          console.log(res);
          vm.response = res?.data
        })
        .catch((e) => {
          console.log(e);
        });
  },
  getRes(){
    const vm = this
    return vm.response
  }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
